import React, { useEffect, useRef, useState } from 'react'
import Footer from '../layout/Footer'
import { IoIosArrowBack } from "react-icons/io";
import BlogHeader from '../layout/BlogHeader';
import { GrFacebookOption } from "react-icons/gr";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { IoLinkOutline } from "react-icons/io5";

const InternalBlog = () => {

  const [activeSection, setActiveSection] = useState("introduction");
  const scrollableContainerRef = useRef(null);

  // Sections IDs
  const sections = [
    "introduction",
    "What product-software-development-platform?",
    "What is software-development-platform?",
    "Difference between low-Product and composable software development",
  ];

  // Scroll Event Listener
  useEffect(() => {
    const handleScroll = () => {
      const scrollableContainer = scrollableContainerRef.current;
      if (!scrollableContainer) return;

      const scrollPosition = scrollableContainer.scrollTop + 200;

      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const { offsetTop, offsetHeight } = sectionElement;
          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            setActiveSection(section);
          }
        }
      });
    };

    const scrollableContainer = scrollableContainerRef.current;
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [sections]);

  const handleMenuClick = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const scrollableContainer = scrollableContainerRef.current;
    if (sectionElement && scrollableContainer) {
      scrollableContainer.scrollTo({
        top: sectionElement.offsetTop - 150,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div ref={scrollableContainerRef} className='h-screen overflow-y-auto'>
      <BlogHeader />
      <section className='pb-5 pt-[100px] bg-[#F5F9FF]'>
        <div className='container-fluid !px-[100px]'>
          <div className="row">
            <div className="col-3">
              <a href='/blogs' className='flex items-center gap-3 mb-5 text-black'>
                <IoIosArrowBack /> <span className='underline underline-offset-4'>Back to Blog</span>
              </a>
            </div>
            <div className="col-6">
              <span className='bg-light-purple inline-block rounded-pill text-theme-color px-[18px] py-[8px] border-[1px] border-theme-color'>Marketing</span>
              <h1 className='my-3 font-semibold'>Customers expect more in a crowded marketplace, and want their expectations exceeded</h1>
              <div className="flex items-center justify-between text-sm">
                <p className='mb-0'>23 January 2025 · 20 minute read</p>
                <div className='flex items-center gap-2'>
                  <a href="#" className='w-[40px] h-[40px] hover:bg-light-purple rounded-full border flex items-center justify-center text-black text-lg'><GrFacebookOption /></a>
                  <a href="#" className='w-[40px] h-[40px] hover:bg-light-purple rounded-full border flex items-center justify-center text-black text-lg'><FaXTwitter /></a>
                  <a href="#" className='w-[40px] h-[40px] hover:bg-light-purple rounded-full border flex items-center justify-center text-black text-lg'><FaInstagram /></a>
                  <a href="#" className='w-[40px] h-[40px] hover:bg-light-purple rounded-full border flex items-center justify-center text-black text-lg'><IoLinkOutline /></a>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="row !mt-[70px]">
              <div class="col-3">
                <div class="d-flex flex-column gap-2 bg-light-purple rounded-lg simple-list-example-scrollspy py-4 px-[30px] sticky top-[100px]">
                  <h1 className='text-xl text-center font-semibold'>Table of Content</h1>
                  {sections.map((section, index) => (
                    <button
                      key={index}
                      onClick={() => handleMenuClick(section)}
                      className={`p-1 text-start hover:!underline rounded relative font-medium cursor-pointer text-black ${activeSection === section && "line"}`}
                    >
                      {section.replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())}
                    </button>
                  ))}
                </div>
              </div>
              <div class="col-6">
                <div>
                  <section id="introduction">
                    <img src='/assets/images/zunno-blog/blog.png' className='w-full object-cover rounded-[16px] mb-5' />
                    <h4 className='!text-[24px] mb-4'>Introduction</h4>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                  </section>

                  <section id='What product-software-development-platform?'>
                    <h4 className='!text-[24px] my-4'>What Prodcut software development platform?</h4>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                  </section>

                  <div className="bg-[#e6ebf5] my-5 rounded-lg p-4">
                    <div className="row">
                      <div className="col-6">
                        <h1 className="text-[32px] font-bold">Create marketing today</h1>
                        <p className=''>100s of Marketing trust us to help them scale.</p>
                        <button className="bg-theme-color text-white px-[25px] py-[10px] rounded-lg">Book a demo</button>
                        <p className='text-sm mt-4 mb-0'>By proceeding you agree to Zunno.ai’ <a href="#" className='text-black !underline'>privacy policy</a> and <a href="#" className='text-black !underline'>terms and conditions</a></p>
                      </div>
                      <div className="col-6">
                        <img src="/assets/images/zunno-blog/blog5.png" alt="blog" />
                      </div>
                    </div>
                  </div>

                  <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                    chain-of-thought. These advanced reasoning capabilities provide new avenues for
                    improving the safety and robustness of our models. In particular, our models can reason
                    about our safety policies in context when responding to potentially unsafe prompts,
                    through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                    spending countless hours writing, testing‌ and debugging code.</p>
                  <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                    chain-of-thought. These advanced reasoning capabilities provide new avenues for
                    improving the safety and robustness of our models. In particular, our models can reason
                    about our safety policies in context when responding to potentially unsafe prompts,
                    through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                    spending countless hours writing, testing‌ and debugging code.</p>

                  <section id='What is software-development-platform?'>
                    <h4 className='!text-[24px] my-4'>What is software-development-platform?</h4>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                  </section>

                  <section id='Difference between low-Product and composable software development'>
                    <h4 className='!text-[24px] my-4'>Difference between low-Product and composable software development</h4>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                    <p className='text-black/50'>The o1 model series is trained with large-scale reinforcement learning to reason using
                      chain-of-thought. These advanced reasoning capabilities provide new avenues for
                      improving the safety and robustness of our models. In particular, our models can reason
                      about our safety policies in context when responding to potentially unsafe prompts,
                      through deliberative alignment task. It required extensive coding knowledge and effort, with developers
                      spending countless hours writing, testing‌ and debugging code.</p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default InternalBlog