import React from 'react'

const BlogBanner = () => {
    return (
        <section>
            <div className='blog_banner'>
                <div className='container lg:!px-[50px]'>
                    <h1 className='texts-gradient text-center mt-4 lg:mb-5 mb-4 pb-3 lg:text-[45px] text-[35px] font-[700] font-new'>Zunno Blogs</h1>
                    <img src='/assets/images/zunno-blog/blog1.png' className='w-full mx-auto' />

                    <div className='lg:w-[600px] lg:ms-[100px] md:mt-[-100px] mt-[-50px] relative z-10'>
                        <div className='mx-[20px] bg-white shadow-md rounded-lg lg:p-[40px] p-[20px]'>
                            <span className='bg-theme-color text-white mb-3 inline-block px-3 py-1 rounded-[6px] text-sm leading-[20px]'>Technology</span>
                            <h2 className='mb-4 lg:text-[32px] md:text-[28px] text-[24px]'>Bringing Generative AI to Photos with Zunno</h2>
                            <div className='flex items-center gap-3'>
                                <img src='/assets/images/zunno-blog/user.svg' />
                                <div className='d-sm-flex items-center gap-3'>
                                    <span className='text-black/50 block'>Jason Francisco</span>
                                    <span className='text-black/50'>September 20, 2023</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogBanner