import React from 'react'
import LatestBlog from './LatestBlog'
import BlogBanner from './BlogBanner'
import MoreBlog from './MoreBlog'
import StayDate from './StayDate'
import Footer from '../../layout/Footer'
import BlogHeader from '../../layout/BlogHeader'

const Index = () => {
  return (
    <div className='overflow-y-auto h-screen'>
      <BlogHeader />
      <BlogBanner />
      <LatestBlog />
      <MoreBlog />
      <StayDate />
      <Footer />
    </div>
  )
}

export default Index