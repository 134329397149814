import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./style/Style.scss";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import LoadingLogo from "./common/logoLoader.js";
import MyProject from "./components/MyProject.js";
import Index from "./components/zunno-blog/Index.js";
import InternalBlog from "./common/InternalBlog.js";

// Lazy-loaded components
const Login = React.lazy(() => import("./auth/login/Login.js"));
const ResetPassword = React.lazy(() => import("./auth/login/ResetPassword.js"));
const SignUpEmail = React.lazy(() => import("./auth/register/SignUpEmail.js"));
const SendCode = React.lazy(() => import("./auth/register/SendCode.js"));
const CreateAccount = React.lazy(() =>
  import("./auth/register/CreateAccount.js")
);
const Welcome = React.lazy(() => import("./auth/Welcome.js"));
const Wrapper = React.lazy(() => import("./layout/Wrapper.js"));
const GetStarted = React.lazy(() => import("./components/GetStarted.js"));
const LoginEmailCode = React.lazy(() =>
  import("./auth/login/LoginEmailCode.js")
);
const LoginPassword = React.lazy(() => import("./auth/login/LoginPassword.js"));
const SettingIndex = React.lazy(() => import("./components/setting/Index.js"));
const PersonalInfo = React.lazy(() =>
  import("./components/setting/PersonalInfo.js")
);
const Security = React.lazy(() => import("./components/setting/Security.js"));
const ActiveSection = React.lazy(() =>
  import("./components/setting/ActiveSection.js")
);
const BillingCentral = React.lazy(() =>
  import("./components/setting/BiilingCentral.js")
);
const PaymentDetails = React.lazy(() =>
  import("./components/setting/PaymentDetails.js")
);
const Subscription = React.lazy(() =>
  import("./components/setting/Subscription.js")
);
const PurchaseHistory = React.lazy(() =>
  import("./components/setting/PurchaseHistory.js")
);

function App() {
  return (
    <BrowserRouter>
      {/* <Suspense fallback={<LoadingLogo />}> */}
      <RoutesWithSuspense />
      {/* </Suspense> */}
    </BrowserRouter>
  );
}

function RoutesWithSuspense() {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/login-code" element={<LoginEmailCode />} />
      <Route path="/login-password" element={<LoginPassword />} />

      <Route path="/signup-email" element={<SignUpEmail />} />
      <Route path="/send-code" element={<SendCode />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/my-project" element={<MyProject />} />
      <Route path="/blogs" element={<Index />} />
      <Route path="/internal-blog" element={<InternalBlog />} />

      <Route
        element={
          // <Suspense fallback={<LoadingLogo />}>
          <Wrapper />
          // </Suspense>
        }
      >
        <Route path="/home" element={<GetStarted />} />
      </Route>

      <Route
        path="/settings"
        element={
          // <Suspense fallback={<LoadingLogo />}>
          <SettingIndex />
          // </Suspense>
        }
      >
        <Route
          path=""
          element={
            // <Suspense fallback={<LoadingLogo />}>
            <PersonalInfo />
            // </Suspense>
          }
        />
        <Route
          path="security"
          element={
            // <Suspense fallback={<LoadingLogo />}>
            <Security />
            // </Suspense>
          }
        />
        <Route
          path="session"
          element={
            // <Suspense fallback={<LoadingLogo />}>
            <ActiveSection />
            // </Suspense>
          }
        />
        <Route
          path="billing"
          element={
            // <Suspense fallback={<LoadingLogo />}>
            <BillingCentral />
            // </Suspense>
          }
        >
          <Route
            path=""
            element={
              // <Suspense fallback={<LoadingLogo />}>
              <PaymentDetails />
              // </Suspense>
            }
          />
          <Route
            path="invoices"
            element={
              // <Suspense fallback={<LoadingLogo />}>
              <Subscription />
              // </Suspense>
            }
          />
          <Route
            path="subscription"
            element={
              // <Suspense fallback={<LoadingLogo />}>
              <PurchaseHistory />
              // </Suspense>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
