import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

const BlogHeader = () => {

    return (
        <div className={`shadow-sm backdrop-blur-sm bg-white/80 py-1 sticky top-0 z-20`}>
            <Navbar expand="lg" className="!py-[12px]">
                <Container fluid className={`justify-between !px-[24px]`}>
                    <div className='flex items-center gap-4'>

                        <div className='flex items-center gap-2'>
                            <Link to={'/home'}><img src='/assets/images/logo.svg' alt='logo' className='mb-1 w-[120px]' /></Link>
                            {/* <div
                                className="text-theme-color bg-theme-color/10 py-[2px] px-[8px] font-[600] text-[12px] rounded-pill"
                                style={{ letterSpacing: "1px" }}
                            >
                                BETA
                            </div> */}
                            <span className='text-theme-color text-[22px] font-bold'>Blog</span>
                        </div>
                    </div>

                    <div className="flex items-center gap-5 blog_menu">
                        <a href="#" className='text-black'>Products</a>
                        <a href="#" className='text-black'>Features</a>
                        <a href="#" className='text-black'>Company</a>
                        <a href="#" className='text-black'>Resources</a>
                    </div>
                    <div>
                        <a href='/' className='bg-theme-color text-white px-[20px] py-[8px] rounded-lg opacity-0'>
                            Sign in
                        </a>
                    </div>
                </Container>
            </Navbar>
        </div>
    )
}

export default BlogHeader