import React from 'react'
import { GrCheckmark } from "react-icons/gr";

const MonthData = ({ padding, text }) => {

    return (
        <div>
            <div className='row gy-4'>
                <div className='col-lg-3'>
                    <div className={`${padding} flex flex-col justify-between border-[2px] border-[#2E2E3A33] bg-white rounded-xl h-full`}>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <span className='text-theme-color inline-block font-semibold mb-2'>Free</span>
                                <p className={`${text} font-bold`}>$49 <span className='text-base text-black/50 font-normal'>/month</span></p>

                                <p className='text-sm leading-7 text-black/50'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p>
                            </div>
                            <div className='col-lg-12 col-md-6'>
                                <ul className='mb-0 pl-0 list-inside'>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Brand Kits</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Quality</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Dimension</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />365 days data storage</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />No.of credits</li>
                                </ul>
                            </div>
                        </div>

                        <button className='bg-theme-color text-white w-full rounded py-3 mt-4'>Subscribe</button>
                    </div>
                </div>

                <div className='col-lg-3'>
                    <div className='back_gradient rounded-xl p-[2px]'>
                        <div className={`${padding} flex flex-col rounded-xl justify-between bg-white relative`}>
                            <div className='row'>
                                <div className='col-lg-12 col-md-6'>
                                    {/* <label className='bg-theme-color px-2 py-1 inline-block text-[12px] text-white rounded absolute -top-3 right-4'>Most popular</label> */}
                                    <span className='text-theme-color inline-block font-semibold mb-2'>Plan 1</span>
                                    <p className={`${text} font-bold`}>$49 <span className='text-base text-black/50 font-normal'>/month</span></p>

                                    <p className='text-sm leading-7 text-black/50'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do admin</p>
                                </div>
                                <div className='col-lg-12 col-md-6'>
                                    <ul className='mb-0 pl-0'>
                                        <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Brand Kits</li>
                                        <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Quality</li>
                                        <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Dimension</li>
                                        <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />365 days data storage</li>
                                        <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />No.of credits</li>
                                    </ul>
                                </div>
                            </div>
                            <button className='bg-theme-color text-white w-full rounded py-3 mt-4'>Subscribe</button>
                        </div>
                    </div>
                </div>

                <div className='col-lg-3'>
                    <div className={`${padding} flex flex-col justify-between border-[2px] border-[#2E2E3A33] bg-white rounded-xl h-full`}>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <span className='text-theme-color inline-block font-semibold mb-2'>Plan 2</span>
                                <p className={`${text} font-bold`}>$49 <span className='text-base text-black/50 font-normal'>/month</span></p>

                                <p className='text-sm leading-7 text-black/50'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p>
                            </div>
                            <div className='col-lg-12 col-md-6'>
                                <ul className='mb-0 pl-0'>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Brand Kits</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Quality</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Dimension</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />365 days data storage</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />No.of credits</li>
                                </ul>
                            </div>
                        </div>

                        <button className='bg-theme-color text-white w-full rounded py-3 mt-4'>Subscribe</button>
                    </div>
                </div>

                <div className='col-lg-3'>
                    <div className={`${padding} flex flex-col justify-between border-[2px] border-[#2E2E3A33] bg-white rounded-xl h-full`}>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <span className='text-theme-color inline-block font-semibold mb-2'>Plan 2</span>
                                <p className={`${text} font-bold`}>$49 <span className='text-base text-black/50 font-normal'>/month</span></p>

                                <p className='text-sm leading-7 text-black/50'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p>
                            </div>
                            <div className='col-lg-12 col-md-6'>
                                <ul className='mb-0 pl-0'>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Brand Kits</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Quality</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />Dimension</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />365 days data storage</li>
                                    <li className='flex items-center mb-3 gap-2'><GrCheckmark className='text-theme-color' />No.of credits</li>
                                </ul>
                            </div>
                        </div>

                        <button className='bg-theme-color text-white w-full rounded py-3 mt-4'>Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonthData