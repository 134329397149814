import React, { useState } from 'react'
import { Modal, Navbar } from 'react-bootstrap'
import MonthData from './MonthData'
import AnnuallyData from './AnnuallyData'

const SubsModal = ({ showModal, showToggle }) => {
    const [isMonthly, setIsMonthly] = useState(true);

    const handleMonthlyClick = () => {
        setIsMonthly(true);
    };

    const handleAnnuallyClick = () => {
        setIsMonthly(false);
    };
    return (
        <>
            <Modal show={showModal} onHide={showToggle} size='xxl' centered scrollable>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>
                        <div className='flex items-center gap-1'>
                            <Navbar.Brand
                                onClick={() => navigate('/')}
                                className="select-none me-0 flex-grow md:flex-grow-0 d-flex gap-1 justify-content-sm-start items-center justify-content-center cursor-pointer"
                            >
                                <img
                                    src="/assets/images/logo.svg"
                                    className="w-[130px]"
                                    alt="logo"
                                />
                            </Navbar.Brand>
                            <div class="text-theme-color bg-light-purple py-[2px] px-[8px] font-[600] text-[12px] rounded-pill">BETA</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-[60px]">
                        <div>
                            <label className='flex justify-center'>
                                <input type='checkbox' className='d-none switch' onChange={() => { }} checked={!isMonthly} />
                                <div className='flex items-center rounded-pill p-1 switch_tab bg-light-purple cursor-pointer w-[305px]'>
                                    <div className='relative flex-grow'>
                                        <div className={`w-1/2 h-full rounded-pill bg-theme-color layer duration-500 absolute ${isMonthly ? 'left-0' : 'left-1/2'}`}></div>
                                        <span onClick={handleMonthlyClick} className={`px-3 py-2 inline-block w-1/2 text-center relative z-[2px] ${isMonthly ? 'text-white' : 'text-gray-500'}`}>
                                            Monthly
                                        </span>
                                        <span onClick={handleAnnuallyClick} className={`px-3 py-2 inline-block w-1/2 text-center relative z-[2px] ${isMonthly ? 'text-gray-500' : 'text-white'}`}>
                                            Yearly <span className={`${isMonthly ? 'text-black' : 'text-black'} bg-white py-1 px-2 text-[12px] rounded-pill`}>22% OFF</span>
                                        </span>
                                    </div>
                                </div>
                            </label>

                            <div className="mt-5">
                                {isMonthly ? <MonthData text='text-4xl' padding='md:p-[40px] p-[20px]' /> : <AnnuallyData text='text-4xl' padding='md:p-[40px] p-[20px]' />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SubsModal