import React from 'react'

const StayDate = () => {
    return (
        <section className='container !px-[50px]'>
            <div className='py-[70px] text-white text-center mb-5 rounded-xl relative' style={{ background: 'linear-gradient(94.88deg, #3749C3 1.38%, #3C95CA 95.58%)' }}>
                <h1 className='mb-4 font-bold'>Bring your ideas to life in minutes</h1>
                <p className='text-xl'>Express yourself with the world’s easiest design program</p>
                <button className='bg-white text-theme-color hover:!bg-black hover:text-white rounded-lg px-[20px] py-[8px] mt-4'>Design</button>
                <img src="/assets/images/zunno-blog/OBJECTS.png" alt="OBJECTS" className='absolute bottom-0 right-0'/>
            </div>
        </section>
    )
}

export default StayDate