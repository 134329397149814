import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import FeedbackModal from '../common/FeedbackModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import MonthData from '../common/MonthData';
import AnnuallyData from '../common/AnnuallyData';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SubsModal from '../common/SubsModal';
import { MdArrowOutward } from "react-icons/md";

const Header = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const dropdownRef = useRef(null);
    const notificationRef = useRef(null);
    const [profile, setProfile] = useState(false);
    const [notification, setNotification] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState("");
    const [show1, setShow1] = useState(false);
    const toggle = () => { setShow1(!show1); };
    const [showModal, setShowModal] = useState(false);
    const showToggle = () => { setShowModal(!showModal) }

    const profileToggle = () => {
        if (activeDropdown === "profile") {
            setActiveDropdown("");
            setProfile(false);
        } else {
            setActiveDropdown("profile");
            setProfile(true);
            setNotification(false);
        }
    };
    const notificationToggle = () => {
        if (activeDropdown === "notification") {
            setActiveDropdown("");
            setNotification(false);
        } else {
            setActiveDropdown("notification");
            setNotification(true);
            setProfile(false);
        }
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            notificationRef.current &&
            !notificationRef.current.contains(event.target)
        ) {
            setActiveDropdown("");
            setNotification(false);
            setProfile(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`${location.pathname === '/settings' && 'border-b'} sticky top-0 z-20`}>
            <Navbar expand="lg" className="!py-[12px]">
                <Container fluid className={`justify-between ${location.pathname === '/setting' ? '!px-[60px]' : '!px-[24px]'}`}>
                    <div className='flex items-center gap-4'>
                        {/* {
                            activeOption === 'Home' && ( */}

                        <div className='flex items-center gap-2'>
                            <Link to={'/home'}><img src='/assets/images/logo.svg' alt='logo' className='mb-1 w-[120px]' /></Link>
                            <div
                                className="text-theme-color bg-theme-color/10 py-[2px] px-[8px] font-[600] text-[12px] rounded-pill"
                                style={{ letterSpacing: "1px" }}
                            >
                                BETA
                            </div>
                        </div>
                        {/* )
                        } */}
                        {/* <div>
                            <button className="flex items-center gap-2 relative group" type="button" >
                                Gen AI <IoIosArrowDown className='text-theme-color/50 group-hover:-rotate-180 duration-300' />
                                <ul className="w-[200px] z-10 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible text-start pl-0 border-0 rounded-lg bg-white duration-300 shadow-[0px_2.8px_28.2px_#0000001A] group-hover:custom-dropdown py-2 absolute top-[30px]">
                                    <li><a className="px-3 py-1 block !text-xs !text-black/50 italic" href="#">Versions</a></li>
                                    <li><a className="text-black px-3 py-1 block hover:bg-[#E5E6F2]" href="#">Zunno AI <span className='text-gradinet font-medium'>Gen-1</span></a></li>
                                    <li><a className="text-black px-3 py-1 block hover:bg-[#E5E6F2]" href="#">Zunno AI <span className='text-gradinet font-medium'>Gen-2</span></a></li>
                                </ul>
                            </button>
                        </div> */}
                    </div>

                    <div className="flex items-center gap-3 ms-auto">
                        <div>
                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <span className="!text-xs p-1 inline-block">You have 20 boosts left today</span>
                                    </Tooltip>
                                }
                            >
                                <div className='flex cursor-pointer relative group text-sm items-center gap-1'>
                                    <img
                                        src="/assets/images/svg/coin.svg"
                                        className="w-[24px]"
                                        alt="coin"
                                    />
                                    20
                                </div>
                            </OverlayTrigger>
                        </div>

                        <div onClick={showToggle} className="flex cursor-pointer items-center gap-1 bg-[#e9ebf8] text-black text-sm rounded-lg py-1 px-3">
                            <img src="/assets/images/svg/kings.svg" alt="king" className='fill-theme-color' /> Upgrade
                        </div>

                        <div className="group rounded-full d-sm-flex hidden justify-center items-center relative cursor-pointer duration-300" ref={notificationRef}>
                            <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <span className="!text-xs p-1 inline-block">Notifications</span>
                                    </Tooltip>
                                }
                            >
                                <img src="/assets/images/svg/bell.svg" className="w-[20px]" onClick={notificationToggle} />
                            </OverlayTrigger>
                            <div
                                ref={dropdownRef}
                                className={`${notification ? "opacity-100 visible" : "opacity-0 invisible"
                                    } duration-500 bg-white shadow w-[300px] z-50 p-3 absolute top-full mt-2 rounded-lg right-0`}
                            >
                                <h1 className="text-2xl text-center">Notifications</h1>
                                <img src="/assets/images/svg/notification.svg" alt="notification" className='mx-auto w-[150px] my-4' />
                                <p className='text-theme-color/80 text-center'>You don’t have any notification</p>

                            </div>
                        </div>

                        {/* <div
                            onClick={toggle}
                            className="text-black relative cursor-pointer group d-sm-flex hidden items-center justify-center rounded-full"
                        >
                            <img
                                src="/assets/images/svg/feedback.svg"
                                className="w-[20px]"
                                alt="feedback"
                            />
                            <div className="bg-[#616161] p-2 rounded-lg text-[12px] duration-300 opacity-0 invisible group-hover:!opacity-100 group-hover:!visible text-white absolute top-[180%]">
                                Feedback
                            </div>
                        </div> */}

                        <div className="relative d-sm-block hidden group" ref={dropdownRef}>
                            <div className='flex items-center gap-2 cursor-pointer'>
                                <div onClick={profileToggle} className="w-[32px] h-[32px] bg-theme-color border-[1px] border-theme-color font-bold text-white rounded-full flex items-center justify-center cursor-pointer">
                                    J
                                </div>
                                <IoIosArrowDown className='group-hover:-rotate-180 duration-300' />
                            </div>
                            <div className={`group-hover:!opacity-100 z-50 group-hover:!visible opacity-0 invisible duration-500 bg-white shadow w-[250px] p-[12px] absolute top-full mt-2 rounded-lg right-0`}>
                                <div className="flex items-center gap-3 border-b">
                                    <div className="flex flex-col">
                                        <span className='text-sm p-[6px]'>jemas.scalf09@gmail.com</span>
                                    </div>
                                </div>
                                <a href="/settings" className="text-black flex items-center rounded my-1 text-sm p-[6px] hover:bg-light-purple" >
                                    Settings
                                </a>
                                <a href="http://lens.zunno.io/" target='_blank' rel="noreferrer" className="text-black icon_animation flex items-center justify-between rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                    <div className='flex items-center gap-2'>
                                        Zunno Lens  <div className="text-theme-color bg-theme-color/10 py-[1px] px-[8px] font-[600] text-[10px] rounded-pill" style={{ letterSpacing: "1px" }}>BETA</div>
                                    </div>
                                    <MdArrowOutward className='icon duration-300 text-lg' />
                                </a>
                                {/* <a href="#refer" className="text-black flex items-center rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                    Refer Zunno AI
                                </a> */}
                                <a href="/blogs" target='_blank' className="text-black flex items-center rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                    Blogs
                                </a>

                                <a onClick={toggle} className="text-black cursor-pointer flex items-center rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                    Feedback
                                </a>
                                <a href="#refer" className="text-black relative main_menu flex items-center justify-between hover:bg-light-purple rounded my-1 text-sm p-[6px]">
                                    Legal
                                    <IoIosArrowDown className="-rotate-90" />

                                    <div className={`duration-500 sub_menu absolute z-10 right-[100%] pe-[18px] top-0`}>
                                        <div className='bg-white shadow w-[250px] p-[12px] rounded-lg'>
                                            <a href="#privacy" className="text-black flex items-center justify-between rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                                Privacy Statement <MdArrowOutward className='icon duration-300 text-lg' />
                                            </a>
                                            <a href="#third-party" className="text-black flex items-center justify-between rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                                Third Party Notice <MdArrowOutward className='icon duration-300 text-lg' />
                                            </a>
                                            <a href="#terms" className="text-black flex items-center justify-between rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                                Terms of Use <MdArrowOutward className='icon duration-300 text-lg' />
                                            </a>
                                            {/* <a href="#faqs" className="text-black flex items-center rounded my-1 text-sm p-[6px] hover:bg-light-purple">
                                                FAQs
                                            </a> */}
                                        </div>
                                    </div>
                                </a>
                                {/* <div className="my-1 border-t"></div> */}
                                <a href="/" className="text-black flex items-center rounded py-1 text-sm p-[6px] hover:bg-light-purple">
                                    Sign out of Zunno AI
                                </a>
                            </div>
                        </div>

                        {/* <div onClick={barToggle} className="d-sm-none">
                            <img src="/assets/images/dots.svg" alt="dots" />
                        </div> */}
                    </div>
                </Container>
            </Navbar>

            <FeedbackModal show={show1} toggle={toggle} />
            <SubsModal showModal={showModal} showToggle={showToggle} />
        </div>
    )
}

export default Header