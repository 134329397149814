import React from 'react'
import Header from '../layout/Header'

const MyProject = () => {
    return (
        <div className='bg-light-purple h-screen'>
            <Header />
            <div className='bg-white h-full rounded-[12px_12px_0_0] mx-[16px]'>
                <div className='container'>
                    <div className='pt-5'>
                        <h1 className="text-[24px]">All Projects</h1>
                        <div className='project mt-[150px] text-center'>
                            <img src="/assets/images/svg/subscription.svg" alt="subs" className='mx-auto' />
                            <p className='text-black/50 text-center text-xl mt-4'>You haven't created any projects yet!</p>
                            <a href='/home' className='bg-theme-color text-white mt-3 inline-block rounded-lg px-[20px] py-[10px]'>Start Creating</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProject