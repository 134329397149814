import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import { motion } from 'framer-motion';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const MoreBlog = () => {
    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const option = {
        loop: false,
        margin: 0,
        nav: false,
        dots: false,
        items: 4,
    }

    return (
        <>
            <section className='py-[50px] bg-[#CADDFF]/30 mt-5'>
                <div className='container !px-[50px]'>
                    <h1 className='lg:text-[45px] font-[900] md:text-[28px] roboto text-[24px] mb-5'>More from the <br /> Zunno Blog</h1>

                    {/* <h2 className='md:text-[24px] text-xl mb-3'>All blog posts</h2> */}
                    <div className='flex items-center gap-[30px] mb-5 whitespace-nowrap text-ellipsis overflow-x-auto scrolling'>
                        <div className='bg-black text-white px-[20px] py-[8px] rounded-lg cursor-pointer'>Latest</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>Marketing</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>AI Video</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>Design</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>Creator</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>Trending</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>Zunno AI</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>What’s Cookies</div>
                        <div className='bg-transparent text-black px-[20px] py-[8px] rounded-lg cursor-pointer'>Latest</div>
                    </div>
                    <div className='row gy-4 space'>
                        <div className="col-lg-3 col-md-6">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog.png" className='w-full h-[200px]' />
                                    </div>
                                    <div className={`p-4 duration-300`}>
                                        <span className='bg-[#FFE780] inline-block text-black text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="/internal-blog" className='text-black mt-[24px] block'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog14.png" className='w-full h-[330px] object-cover' />
                                    </div>
                                    <div className={`p-4 bg-5 duration-300`}>
                                        <span className='bg-[#FFE780] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog11.png" className='w-full h-[330px] object-cover' />
                                    </div>
                                    <div className={`p-4 duration-300`}>
                                        <span className='bg-[#B9E6F9] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="/internal-blog" className='text-black mt-[24px] block'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog13.png" className='w-full h-[200px]' />
                                    </div>
                                    <div className={`p-4 bg-6 duration-300`}>
                                        <span className='bg-[#B9E6F9] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog7.png" className='w-full h-[200px]' />
                                    </div>
                                    <div className={`p-4 bg-3 duration-300`}>
                                        <span className='bg-[#FFA8D7] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="/internal-blog" className='text-black mt-[24px] block'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog12.png" className='w-full h-[200px] object-cover' />
                                    </div>
                                    <div className={`p-4 bg-7 duration-300`}>
                                        <span className='bg-[#FFE780] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog11.png" className='w-full h-[330px] object-cover' />
                                    </div>
                                    <div className={`p-4 bg-4 duration-300`}>
                                        <span className='bg-[#B9E6F9] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="/internal-blog" className='text-black mt-[24px] block'>
                                <div className='rounded-[16px] group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden'>
                                        <img src="/assets/images/zunno-blog/blog13.png" className='w-full h-[200px]' />
                                    </div>
                                    <div className={`p-4 bg-8 duration-300`}>
                                        <span className='bg-[#B9E6F9] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Marketing and Trends</span>
                                        <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Bill Walsh leadership lessons like to know</h1>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    {/* <div className='text-center mt-5'>
                        <button className='bg-theme-color text-white rounded-lg px-[20px] py-[8px]'>Read More</button>
                    </div> */}
                </div>
            </section>

            <section className='py-5'>
                <div className='container !px-[50px]'>
                    <h1 className='lg:text-[45px] font-[900] roboto md:text-[28px] text-[24px] mb-5'>Zunno AI for <br />Everyone</h1>

                    <OwlCarousel className="owl-theme w-full package_slider" {...option}>
                        <div className="item flex flex-col">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] m-2 flex flex-col h-full group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden p-2'>
                                        <img src="/assets/images/zunno-blog/blog.png" className='w-full h-[200px] rounded-xl' />
                                    </div>
                                    <div className={`p-3 duration-300`}>
                                        <span className='bg-[#FFE780] inline-block text-black text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Inspirational</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Marketing: Customers expect more in a crowded</h1>
                                        </div>
                                        <div className='text-sm text-[#777777]'>
                                            <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="item flex flex-col">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] m-2 flex flex-col h-full group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden p-2'>
                                        <img src="/assets/images/zunno-blog/blog6.png" className='w-full h-[200px] rounded-xl' />
                                    </div>
                                    <div className={`p-3 duration-300`}>
                                        <span className='bg-[#FFA8D7] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Inspirational</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Marketing: Customers expect more in a crowded</h1>
                                        </div>
                                        <div className='text-sm text-[#777777]'>
                                            <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="item flex flex-col">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] m-2 flex flex-col h-full group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden p-2'>
                                        <img src="/assets/images/zunno-blog/blog7.png" className='w-full h-[200px] rounded-xl' />
                                    </div>
                                    <div className={`p-3 duration-300`}>
                                        <span className='bg-[#FFE780] inline-block text-black text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Inspirational</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Marketing: Customers expect more in a crowded</h1>
                                        </div>
                                        <div className='text-sm text-[#777777]'>
                                            <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="item flex flex-col">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] m-2 flex flex-col h-full group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden p-2'>
                                        <img src="/assets/images/zunno-blog/blog8.png" className='w-full h-[200px] rounded-xl' />
                                    </div>
                                    <div className={`p-3 duration-300`}>
                                        <span className='bg-[#B9E6F9] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Inspirational</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Marketing: Customers expect more in a crowded</h1>
                                        </div>
                                        <div className='text-sm text-[#777777]'>
                                            <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="item flex flex-col">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] m-2 flex flex-col h-full group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden p-2'>
                                        <img src="/assets/images/zunno-blog/blog9.png" className='w-full h-[200px] rounded-xl' />
                                    </div>
                                    <div className={`p-3 duration-300`}>
                                        <span className='bg-[#FFA8D7] !text-black inline-block text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Inspirational</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Marketing: Customers expect more in a crowded</h1>
                                        </div>
                                        <div className='text-sm text-[#777777]'>
                                            <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="item flex flex-col">
                            <a href="/internal-blog" className='text-black'>
                                <div className='rounded-[16px] m-2 flex flex-col h-full group bg-white overflow-hidden box_shadow'>
                                    <div className='overflow-hidden p-2'>
                                        <img src="/assets/images/zunno-blog/blog10.png" className='w-full h-[200px] rounded-xl' />
                                    </div>
                                    <div className={`p-3 duration-300`}>
                                        <span className='bg-[#FFE780] inline-block text-black text-xs tracking-[1px] px-[10px] py-[6px] uppercase rounded-pill'>Inspirational</span>
                                        <div className='flex align-items-md-start align-items-center my-2 justify-between'>
                                            <h1 className='text-xl'>Marketing: Customers expect more in a crowded</h1>
                                        </div>
                                        <div className='text-sm text-[#777777]'>
                                            <span className='text-sm mt-3 block'>Alec Whitten • 1 Jan 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </OwlCarousel>

                    <div className='text-center mt-5'>
                        <button className='bg-black text-white hover:!bg-[#3f3f3f]  rounded-lg px-[20px] py-[8px]'>Read More</button>
                    </div>
                </div>
            </section >
        </>
    )
}

export default MoreBlog