import React from 'react'
import { ImLinkedin } from "react-icons/im";
import { FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
    return (
        <div className='blog_gradient py-3'>
            <div className="container lg:!px-[50px]">
                <div className='row gy-4 items-center justify-between border-b border-white/50 pb-4 mb-4'>
                    <div className="col-lg-2 col-6 order-1">
                        <a href="/"><img src="/assets/images/white-logo.svg" alt="logo" className='w-[150px]' /></a>
                    </div>
                    <div className="col-lg-8 order-lg-2 order-3">
                        <div className='flex items-center gap-5 justify-center'>
                            <a href="#" className='text-white hover:!underline'>Privacy Policy</a>
                            <a href="#" className='text-white hover:!underline'>Terms and Conditions</a>
                            <a href="#" className='text-white hover:!underline'>Disclaimer</a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-6 order-lg-3 order-2">
                        <div className='flex items-center gap-3 justify-end'>
                            <a href="https://www.linkedin.com/company/zunnoai" className='text-white text-2xl'><ImLinkedin /></a>
                            {/* <a href="#" className='text-white text-2xl'><FaSquareInstagram /></a> */}
                        </div>
                    </div>
                </div>
                <div className='text-center text-white'>Copyright © 2024 Zunno AI. All Rights Reserved.</div>
            </div>
        </div>
    )
}

export default Footer